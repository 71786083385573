$grad-gray: linear-gradient(to top right, #91928730 #D2D5CE30,);
$grad-gray-light: linear-gradient(to top, #FDFCFC, #EFEFEF);

$grad-green: linear-gradient(to top right, #f1f4ef, #c0cbba);
$grad-beige: linear-gradient(to top right, #ceb6a166, #f7f5f266);

.gradient-gray {
  background: $grad-gray;
}

.gradient-gray-light {
  background: $grad-gray-light;
}

.gradient-green {
  background: $grad-green;
}
.gradient-beige {
  background: $grad-beige;
}