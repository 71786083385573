// Import your CSS/SCSS files here:
@import "gradients";
@import "text";
@import "tooltip";

body {
  @apply bg-[#F4F4F4];
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: "branchregular";
  src: url("fonts/branch-webfont.woff2") format("woff2"),
    url("fonts/branch-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.bellezza {
  font-family: "Belleza", sans-serif;
}
.branch {
  font-family: "branchregular";
}
h1 {
  font-size: responsive 35px 80px !important;
  line-height: responsive 33px 78px !important;
}

h2 {
  font-size: responsive 20px 30px !important;
  line-height: responsive 28px 35px !important;
  font-weight: 400;
}
h3 {
  font-size: responsive 30px 55px !important;
  line-height: responsive 38px 53px !important;
  font-weight: 400;
  @apply tracking-tight;
}
.social-btn {
  svg {
    position: absolute;
    width: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
p {
  font-family: "Albert Sans", sans-serif;
  margin-bottom: 15px;
}

.ot-pattern {
  left: -50%;
}
.input-group {
  label {
    color: white;
  }
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  top: 0;
  left: 0;
}

.about-layout-1 {
  padding: 50px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 5%;
    @apply gap-x-10;
  }
  .svg-container {
    display: flex;
    justify-content: center;
  }
}
.about-layout-4 {
  #course-svg-container {
    @apply flex justify-start;
  }
}

.about-layout-2 {
  padding: 50px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 5%;
    padding-left: 0vh;
    justify-content: end;
  }
}

.about-layout-2 img {
  left: 0;
  width: 95%;
  top: 20%;
}

.about-layout-2 .svg-container {
  @media screen and (min-width: 768px) {
    @apply mr-10;
  }
}

.about-layout-3 {
  padding: 50px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 5%;
    @apply gap-x-10;
  }
  .svg-container {
    display: flex;
    justify-content: center;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

#scrollbar {
  position: relative;
  width: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 70%;
  background: #abb4b2;
  // hide on smaller screens
  @media (max-width: 1024px) {
    display: none;
  }
}

#scroller {
  position: relative;
  width: 3px;
  height: 10px;
  background: #abb4b2;
}

.accordion {
  display: flex;
  @media (max-width: 1024px) {
    display: grid;
  }
}

.accordion .left {
  @media (max-width: 1024px) {
    margin-right: 0px;
    width: 100%;
  }
  margin-right: 75px;
  width: 50%;
}

.accordion .right {
  @media (max-width: 1024px) {
    width: 100%;
  }
  width: 50%;
}

.accordion .left .accord-heading {
  @media (max-width: 1024px) {
    display: grid;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  padding-top: 35px;
  padding-bottom: 35px;
  cursor: pointer;
  padding: 10px;
  color: #423032;
  h3 {
    @apply text-lg;
    @media (max-width: 604px) {
      font-size: 1rem;
    }
  }
}

.accord-heading {
  background-color: #d2d5ce;
  mix-blend-mode: multiply;
}

.accordion .left .accord-heading:hover {
  background-color: #e0e1e0;
  color: #423032;
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.accordion .left .accord-heading.active {
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  background-color: #cac2b5;
  border-right: 16px solid #a39b8d;
  line-height: responsive 20px 30px;

  h3 {
    @apply text-2xl;
    @media (max-width: 604px) {
      font-size: 1rem;
    }
  }
}

.accordion .right .accord-content {
  display: none;
  padding: 10px;
  padding-top: 1px;
  overflow: auto; /* or overflow: scroll; */
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS devices */
  scrollbar-width: none; /* Hide the scrollbar on modern browsers */
  -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Microsoft Edge */

  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers */
  }
}

.accordion .right .accord-content.active {
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: block;
}

.wrapper {
  padding-left: 200px;
  padding-right: 200px;
  max-width: 1680px !important;
  margin: auto !important;
  @media (max-width: 1536px) {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media (max-width: 640px) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.news-wrapper {
  margin: 0 auto;
  max-width: 894px;
  width: 100%;
  @apply px-10;
  @media (max-width: 640px) {
    @apply px-8;
  }
}
.text-block {
  column-count: 2;
  column-gap: 40px;
  p {
    margin-bottom: 20px;
  }
  ol li {
    margin-top: 25px;
    margin-bottom: 15px;
    @apply text-3xl;
  }
  @media screen and (max-width: 768px) {
    column-count: 1;
  }
}

.belleza {
  font-family: "Belleza", sans-serif;
}
.social-btn svg {
  position: absolute;
  width: 20px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bg-video {
  @apply absolute inset-0 overflow-hidden;
  // z-index: 1;
  & > div {
    // width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1920px !important;
    height: 1080px !important;
    min-width: 1920px !important;
    min-height: 1080px !important;
  }
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 14, 2023 */

$font-directory: "/fonts";

@font-face {
  font-family: "apothecaryregular";
  src: url($font-directory + "/apothecary_regular-webfont.woff2")
      format("woff2"),
    url($font-directory + "/apothecary_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.apothecary {
  font-family: "apothecaryregular";
}

@font-face {
  font-family: "apothecary_typewriterregular";
  src: url($font-directory + "/apothecary_typewewriter-webfont.woff2")
      format("woff2"),
    url($font-directory + "/apothecary_typewewriter-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

.button-hover:hover {
  opacity: 0.5 !important;
}

h4 {
  @apply my-5 text-3xl;
}

// <section class="w-[100vw] flex flex-col items-center px-4 md:px-8 pt-9 md:pt-9 text-[#605F4F]">
// 	<div class="flex flex-col justify-center items-center py-14 md:py-20 lg:pt-14 text-center relative min-h-[90vh] md:min-h-[1040px] max-w-[1528px] w-full rounded-3xl overflow-hidden bg-gradient-to-tr from-[#91928730] to-[#D2D5CE30]">

.block-wrapper {
  width: 100vw;
  @apply w-[100vw] flex flex-col items-center px-4 md:px-8 py-[17px] md:py-[17px] text-[#605F4F];
}

.block-inner {
  position: relative;
  @apply flex flex-col justify-center items-center py-14 md:py-20 
  lg:py-36 text-center min-h-[0] max-w-[1528px] w-full px-8
  rounded-3xl overflow-hidden;
}

.long-text {
  p {
    margin-bottom: 20px;
  }
  ol li {
    margin-top: 25px;
    margin-bottom: 15px;
    @apply text-3xl;
  }
  ul li {
    list-style-type: disc;
    margin-left: 25px;
  }
  @apply text-left;
}
.logo-item {
  border-radius: 5px;
  overflow: hidden;
}
.albert-sans {
  font-family: "Albert Sans", sans-serif;
}

#video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#vimeo-player {
  width: 80%;
  height: 80%;
}

.stripe-payment-intents-form {
  width: 100% !important;
  max-width: 600px;
  padding: 25px;
  background: #f9f9f9; /* Adding a light background */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for depth */
  margin-bottom: 2rem;
}

.card-holder {
  div {
    div {
      margin: 1rem 0;
    }
  }
}

.card-data {
  margin-top: 5px;
  margin-bottom: 1rem;
}

.gateway-payment-form input {
  padding: 0.75rem; /* Increased padding for better touch targets */
  width: calc(100% - 1.5rem); /* Adjust width to account for padding */
  line-height: 1.5;
  border: 2px solid #e2e8f0; /* Lighter border color */
  border-radius: 4px; /* Less pronounced border-radius */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.gateway-payment-form input:hover,
.gateway-payment-form input:focus {
  border: 2px solid #605f4f; /* Highlight color on hover and focus */
  outline: none; /* Remove focus outline */
}

/* Column containers */
.gateway-payment-form .grid,
.gateway-payment-form .card-data div div:nth-child(2) {
  display: flex;
  flex-wrap: wrap; /* Ensure wrapping on small screens */
  gap: 15px; /* Replace margin with gap for consistency */
}

/* Columns */
.gateway-payment-form .grid .item,
.gateway-payment-form input.card-expiry,
.gateway-payment-form input.card-cvc {
  flex: 1; /* Flex grow to fill available space */
  min-width: 120px; /* Minimum width for inputs */
}

/* Button Styling */
.gateway-payment-form button {
  background-color: #605f4f; /* Matching the highlight color */
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.gateway-payment-form button:hover {
  background-color: #3730a3; /* Darker shade on hover */
}

/* Error Message Styling */
.error {
  color: #dc2626; /* Red color for errors */
  background: #fee2e2; /* Light red background */
  border-radius: 4px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

#payment-errors {
  background-color: #fee; // Light red/pink background for visibility
  color: #c00; // Darker red for text to indicate an error
  border: 1px solid #c00; // Border to make the error box stand out
  border-radius: 4px; // Rounded corners
  padding: 10px; // Some padding to ensure the text isn't too cramped
  margin-bottom: 20px; // Space between the error message and the Pay Now button
  text-align: center; // Center align the text
  font-size: 0.9em; // Slightly smaller font size
  max-width: 600px;
}
.aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
