$easing: cubic-bezier(0, 1, 0.45, 1); // my custom
// $easing: cubic-bezier(0.55, 0, 0.1, 1); // swift out
// $easing: cubic-bezier(0, 0.55, 0.45, 1); // circ out

@keyframes letter {
    from {
      opacity: 0;
      // transform: translateY(100px) scale(0.8);
      transform: translateY(1em) scale(0.8);
    }
    to {
      opacity: 1;
      transform: none;
      filter: none;
    }
}


[data-action="TextAnim"] {
    opacity: 0;
}

.ta-letter {
  opacity: 0;
  animation: letter 2s $easing forwards;
  top: -0.1em;
  position: relative;
}

.ta-word {
  overflow: hidden;
  display: inline-flex;
  margin-right: 0.15em;
  position: relative;
  padding-top: 5px;
}

@keyframes ta-color {
    from {
        color: currentColor;
    }
    to {
        @apply text-green-200;
    }
}
  
.ta-color {
animation: ta-color 1s ease-out forwards;
animation-delay: 2s;
}

@keyframes button-up {
    from {
        transform: translateY(15px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}


@keyframes heading-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.heading-fade-in {
    animation: heading-fade-in 1s ease-out forwards;
    animation-delay: 0.2s;
}